<template>
    <b-table-simple striped>
        <b-thead>
            <b-th v-if="withAvatar"> User </b-th>
            <b-th class="text-center"> Success </b-th>
            <b-th> Amount </b-th>
            <b-th class="text-center"> AVS </b-th>
            <b-th class="text-center"> CVV </b-th>
            <b-th> Postcode </b-th>
            <b-th> Bank </b-th>
            <b-th> Last 4 </b-th>
            <b-th> Created </b-th>
            <b-th> </b-th>
        </b-thead>
        <b-tbody>
            <b-tr
                :key="authorizeTransaction.id"
                v-for="{
                    authorizeTransaction,
                    ninjaMap,
                    userCreditCard,
                    userCreditCardCountry,
                    bin,
                    binCountry,
                } in authorizeTransactionIndex"
            >
                <td v-if="withAvatar">
                    <user-avatar v-if="ninjaMap" :user="ninjaMap" />
                </td>
                <td class="text-center">
                    <font-awesome-icon
                        v-if="authorizeTransaction.successful"
                        icon="fa-light fa-check-circle"
                        class="text-success"
                    />
                    <font-awesome-icon
                        v-else
                        icon="fa-solid fa-times"
                        class="text-danger"
                    />
                </td>
                <td>${{ authorizeTransaction.amount }}</td>
                <td
                    :class="`text-center text-${authorizeTransaction.avsVariant}`"
                >
                    {{ authorizeTransaction.avs }}
                </td>
                <td
                    :class="`text-center text-${authorizeTransaction.cvvVariant}`"
                >
                    {{ authorizeTransaction.cvv }}
                </td>
                <td>
                    <img
                        v-if="userCreditCardCountry"
                        :src="userCreditCardCountry.flagUrl"
                    />
                    {{ authorizeTransaction.postalCode }}
                </td>
                <td>
                    <b-link
                        v-if="bin?.modern"
                        :to="{
                            name: 'userIndex',
                            query: {
                                binId: bin.id,
                            },
                        }"
                    >
                        <img v-if="binCountry" :src="binCountry.flagUrl" />
                        {{ bin?.bankName }}
                        ({{ bin?.cardType }})
                    </b-link>
                </td>
                <td>
                    {{ userCreditCard?.lastFour }}
                </td>
                <td>
                    <live-timestamp :timestamp="authorizeTransaction.created" />
                </td>
                <td>
                    <b-btn
                        variant="light"
                        size="sm"
                        :href="`/authorizeTransaction/view/${authorizeTransaction.id}`"
                    >
                        View
                    </b-btn>
                </td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
export default {
    props: ["authorizeTransactionIndex", "withAvatar"],
};
</script>