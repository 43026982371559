<template>
    <div class="my-3">
        <div v-if="!currentTab">
            <user-tab-dashboard />
        </div>
        <div v-if="currentTab === 'ips'">
            <user-tab-ips />
        </div>
        <div v-if="currentTab === 'audits'">
            <user-tab-audits />
        </div>
        <div v-if="currentTab === 'devices'">
            <user-tab-devices />
        </div>
        <div v-if="currentTab === 'trackers'">
            <user-tab-trackers />
        </div>
        <div v-if="currentTab === 'identifiers'">
            <user-tab-identifiers />
        </div>
        <div v-if="currentTab === 'fingerprints'">
            <user-tab-fingerprints />
        </div>
        <div v-if="currentTab === 'meta'">
            <user-tab-meta />
        </div>
        <div v-if="currentTab === 'photos'">
            <user-tab-photos />
        </div>
        <div v-if="currentTab === 'videos'">
            <user-tab-videos />
        </div>
        <div v-if="currentTab === 'cities'">
            <user-tab-cities />
        </div>
        <div v-if="currentTab === 'messages'">
            <user-tab-messages />
        </div>
        <div v-if="currentTab === 'browsers'">
            <user-tab-browsers />
        </div>
        <div v-if="currentTab === 'emails'">
            <user-tab-emails />
        </div>
        <div v-if="currentTab === 'reports'">
            <user-tab-reports />
        </div>
        <div v-if="currentTab === 'fine'">
            <user-tab-fines />
        </div>
        <div v-if="currentTab === 'violation'">
            <user-tab-violation />
        </div>
        <div v-if="currentTab === 'security'">
            <user-tab-security />
        </div>
        <div v-if="currentTab === 'keywords'">
            <user-tab-keywords />
        </div>
        <div v-if="currentTab === 'authorizeTransactions'">
            <user-tab-authorize-transactions />
        </div>
        <div v-if="currentTab === 'withdraw'">
            <user-tab-withdraw />
        </div>
        <div v-if="currentTab === 'wiseLogs'">
            <user-tab-wise-logs />
        </div>
        <div v-if="currentTab === 'invites'">
            <user-tab-invites />
        </div>
        <div v-if="currentTab === 'documents'">
            <user-tab-documents />
        </div>
        <div v-if="currentTab === 'root'">
            <user-tab-root />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserTabWithdraw from "@/components/user/tabs/UserTabWithdraw";
import UserTabAuthorizeTransactions from "@/components/user/tabs/UserTabAuthorizeTransactions";
import UserTabDashboard from "@/components/user/tabs/UserTabDashboard";
import UserTabIps from "@/components/user/tabs/UserTabIps";
import UserTabAudits from "@/components/user/tabs/UserTabAudits";
import UserTabTrackers from "@/components/user/tabs/UserTabTrackers";
import UserTabIdentifiers from "@/components/user/tabs/UserTabIdentifiers";
import UserTabFingerprints from "@/components/user/tabs/UserTabFingerprints";
import UserTabMeta from "@/components/user/tabs/UserTabMeta";
import UserTabDevices from "@/components/user/tabs/UserTabDevices";
import UserTabPhotos from "@/components/user/tabs/UserTabPhotos";
import UserTabCities from "@/components/user/tabs/UserTabCities";
import UserTabMessages from "@/components/user/tabs/UserTabMessages";
import UserTabBrowsers from "@/components/user/tabs/UserTabBrowsers";
import UserTabVideos from "@/components/user/tabs/UserTabVideos";
import UserTabEmails from "@/components/user/tabs/UserTabEmails";
import UserTabFines from "@/components/user/tabs/UserTabFines";
import UserTabReports from "@/components/user/tabs/UserTabReports";
import UserTabSecurity from "@/components/user/tabs/UserTabSecurity";
import UserTabKeywords from "@/components/user/tabs/UserTabKeywords";
import UserTabRoot from "@/components/user/tabs/UserTabRoot";
import UserTabInvites from "@/components/user/tabs/UserTabInvites";
import UserTabDocuments from "@/components/user/tabs/UserTabDocuments";
import UserTabViolation from "@/components/user/tabs/UserTabViolation";
import UserTabWiseLogs from "@/components/user/tabs/UserTabWiseLogs";

export default {
    computed: {
        currentTab() {
            return this.$route.params?.tab;
        },
        ...mapState("userEdit", ["user"]),
    },
    methods: {},
    components: {
        UserTabIps,
        UserTabAudits,
        UserTabDashboard,
        UserTabTrackers,
        UserTabDevices,
        UserTabIdentifiers,
        UserTabMeta,
        UserTabFingerprints,
        UserTabRoot,
        UserTabPhotos,
        UserTabMessages,
        UserTabDocuments,
        UserTabCities,
        UserTabBrowsers,
        UserTabVideos,
        UserTabReports,
        UserTabEmails,
        UserTabAuthorizeTransactions,
        UserTabSecurity,
        UserTabFines,
        UserTabKeywords,
        UserTabWithdraw,
        UserTabInvites,
        UserTabViolation,
        UserTabWiseLogs,
    },
};
</script>