<template>
    <generic-page>
        <b-container>
            <div class="d-flex align-items-center justify-content-between my-3">
                <div>
                    <h1 class="mb-0">Video</h1>
                </div>
                <div>
                    <video-training />
                </div>
                <div>
                    <b-dropdown variant="light" right>
                        <template #button-content>
                            Filter
                            <span class="text-info" v-if="$route.query?.filter">
                                ({{ $route.query.filter }})
                            </span>
                        </template>
                        <b-dropdown-item @click="setFilter('approved')">
                            Approved
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('filler')">
                            Filler
                        </b-dropdown-item>
                        <b-dropdown-item @click="setFilter('rejected')">
                            Rejected
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            variant="danger"
                            @click="setFilter(null)"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Reset
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    lg="4"
                    :key="creatorVideo.id"
                    v-for="{
                        creatorVideo,
                        user,
                        totalVideoCount,
                        rejectionRate,
                    } in videos"
                >
                    <div class="d-flex align-items-center">
                        <div class="mr-2">
                            <user-avatar :user="user" />
                        </div>
                        <div class="flex-grow-1">
                            <b-table-simple bordered striped class="mb-0">
                                <b-tbody>
                                    <b-tr>
                                        <b-th> Total Videos </b-th>
                                        <b-td>
                                            {{ totalVideoCount }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th> Rejection Rate </b-th>
                                        <b-td> {{ rejectionRate }}% </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </div>

                    <video-player
                        loop="true"
                        autoplay="true"
                        controls="true"
                        muted="true"
                        :webmUrl="creatorVideo.webmUrl"
                        :mp4Url="creatorVideo.mp4Url"
                        :poster="creatorVideo.previewUrl"
                    />

                    <div class="small bg-light text-center py-2">Duration: {{ creatorVideo.duration }}</div>

                    <b-btn-group class="w-100">
                        <b-dropdown variant="secondary" size="lg">
                            <b-dropdown-item
                                @click="reject(creatorVideo)"
                                class="py-3"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-thumbs-down"
                                />
                                Reject
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="fine(creatorVideo)"
                                class="py-3"
                            >
                                <font-awesome-icon icon="fa-solid fa-gavel" />
                                Fine
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-btn
                            :disabled="saving"
                            @click="filler(creatorVideo)"
                            variant="light"
                            size="lg"
                            class="px-5"
                        >
                            Filler
                        </b-btn>
                        <b-btn
                            :disabled="saving"
                            @click="approve(creatorVideo)"
                            variant="primary"
                            size="lg"
                            class="px-0"
                        >
                            <font-awesome-icon icon="fa-solid fa-thumbs-up" />
                        </b-btn>
                    </b-btn-group>

                    <div v-if="user.primaryPhoto.medium">
                        <div class="small text-secondary p-3 text-center">
                            Compare primary photo to video ensure their face
                            shows for at least 1 second
                        </div>

                        <img
                            class="img-fluid"
                            :src="user.primaryPhoto.medium"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </generic-page>
</template>


<script>
import VideoPlayer from "@/components/utility/VideoPlayer";
import VideoTraining from "@/components/video/VideoTraining";

export default {
    data() {
        return {
            videos: null,
            saving: false,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        reject(creatorVideo) {
            this.saving = true;
            this.api
                .post(`/creatorVideo/${creatorVideo.id}/reject`)
                .then(() => {
                    this.saving = false;
                    this.refresh();
                });
        },
        filler(creatorVideo) {
            this.saving = true;
            this.api
                .post(`/creatorVideo/${creatorVideo.id}/filler`)
                .then(() => {
                    this.saving = false;
                    this.refresh();
                });
        },
        approve(creatorVideo) {
            this.saving = true;
            this.api
                .post(`/creatorVideo/${creatorVideo.id}/approve`)
                .then(() => {
                    this.saving = false;
                    this.refresh();
                });
        },
        fine(creatorVideo) {
            this.saving = true;
            this.api.post(`/creatorVideo/${creatorVideo.id}/fine`).then(() => {
                this.saving = false;
                this.refresh();
            });
        },
        createFine(type) {
            console.log(type);
        },
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router.push({
                name: "videoIndex",
                query,
            });

            this.refresh();
        },
        refresh() {
            this.api
                .post("/creatorVideo/index", {
                    filter: this.$route.query?.filter,
                })
                .then((response) => {
                    this.videos = response.data.videos;
                });
        },
    },
    components: {
        VideoPlayer,
        VideoTraining,
    },
};
</script>